import './styles.scss'

import React from 'react'

const viewForIE = (): React.ReactElement => {
  return (
    <div className="container viewForIE1">
      <div className="container">
        <h1 className="mt-9">Richard Torcato - Resume</h1>
        <p className="lead">Sorry this website does not support your browser.</p>

        <div className="card">
          You can still view Richard Torcato&apos;s resume by downloading these files here:
          <ul style={{ paddingTop: '10px' }}>
            <li>
              <a href={'#'} target="_blank" rel="noopener noreferrer">
                Resume in Adobe PDF format
              </a>
            </li>
            <li>
              <a href={'#'} target="_blank" rel="noopener noreferrer">
                Resume in Microsoft WORD format
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default viewForIE

import { css } from '@emotion/core'
// @import 'styles/theme/variables';
// $strokecolor: #000; //$primary !default;
const style = css`
  border-top: 2px solid currentColor;
  height: 25px;
  width: 30px;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
  transition: all 3ms ease-in;
  outline: none;
  &:before {
    content: '';
    display: block;
    position: absolute;
    height: 2px;
    width: 30px;
    left: 0;
    background: currentColor;
    top: 10px;
    transition: all 0.3s ease-in;
  }
  &:after {
    content: '';
    display: block;
    position: absolute;
    height: 2px;
    width: 30px;
    left: 0;
    background: currentColor;
    bottom: 0;
    transition: all 0.3s ease-in;
  }
  &.active {
    border-color: transparent;
    &:before {
      transform: rotate(45deg);
      width: 33px;
      left: -2px;
    }
    &:after {
      transform: rotate(135deg);
      bottom: 11px;
      width: 33px;
      left: -2px;
    }
  }
`

export default style

import AOS from 'aos'
import { AppProvider } from 'state/context/appContext'
import BrowserCheck from 'components/browser/browserCheck'
import { CounterProvider } from 'state/context/counterContext'
import Footer from 'components/footer'
import { Global } from '@emotion/core'
import Navigation from 'components/navigation'
import PhotoBox from 'components/photoBox'
import React from 'react'
import ViewForIE from 'components/browser/viewForIE'
import globalStyle from 'styles'
import { isWebBrowser } from 'utils'
import smoothscroll from 'smoothscroll-polyfill'
import styleConfig from 'config/styleConfig'
import styled from '@emotion/styled'

//import { useIntersection } from 'react-use'

//import { useOrientation, useWindowSize } from 'react-use'

const PageContentContainer = styled.div`
  display: flex;
  position: relative;
  overflow: hidden;
  min-height: 100vh;
  flex-direction: column;
  margin-top: 80px;
  ${styleConfig.breakPoints.lg} {
    margin-top: 0px;
  }
`
const PageContent = styled.div`
  display: flex;
  font-size: 1.25rem;
  flex-grow: 1;
  position: relative;
  flex-direction: column;
`

interface IProps {
  // to: string;
  children?: React.ReactNode
  alignItems?: string
  justifyContent?: string
}

const aosConfig = {
  anchorPlacement: '"bottom-bottom',
  initClassName: 'aos-init',
  offset: 200,
  duration: 1000,
  mirror: false,
  // Global settings:
  /*
  disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
  startEvent: 'DOMContentLoaded', //'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
  initClassName: 'aos-init', // class applied after initialization
  animatedClassName: 'aos-animate', // class applied on animation
  useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
  disableMutationObserver: false, // disables automatic mutations' detections (advanced)
  debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
  throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)

  // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
  offset: 200, // offset (in px) from the original trigger point
  delay: 0, // values from 0 to 3000, with step 50ms
  duration: 1000, // values from 0 to 3000, with step 50ms
  easing: 'ease-in-out', // default easing for AOS animations
  once: false, // whether animation should happen only once - while scrolling down
  mirror: false, // whether elements should animate out while scrolling past them
  anchorPlacement: '"bottom-bottom', // defines which position of the element regarding to window should trigger the animation
  */
}

const Layout = ({ children }: IProps): React.ReactElement => {
  React.useEffect(() => {
    if (isWebBrowser()) {
      AOS.init(aosConfig)
      // kick off the polyfill!
      smoothscroll.polyfill()
    }
  }, [])

  React.useEffect(() => {
    /*if (AOS && isWebBrowser()) {
      AOS.refresh()
    }
    */
  })
  //const screenOrientation = useOrientation()
  //const { width, height } = useWindowSize()
  /*const [sidebarVisible, setSidebarVisible] = React.useState(true)
  // const [disabled, setDisable] = React.useState(false);

  const handleSideBarBtn = (): void => {
    setSidebarVisible(!sidebarVisible)
  }
  */
  /*
  const intersectionRef = React.useRef(null)
  const intersection = useIntersection(intersectionRef, {
    root: null,
    rootMargin: '0px',
    threshold: 1,
  })
  */

  return (
    <>
      <Global styles={globalStyle} />
      <BrowserCheck ie={true}>
        <ViewForIE />
      </BrowserCheck>
      <BrowserCheck ie={false}>
        <AppProvider>
          <CounterProvider>
            <>
              <Navigation />
              <PageContentContainer>
                {/*<NoCookies />*/}
                <PageContent>{children}</PageContent>
                <Footer />
              </PageContentContainer>
              <PhotoBox />
            </>
          </CounterProvider>
        </AppProvider>
      </BrowserCheck>
    </>
  )
}

export default Layout

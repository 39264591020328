import twConfig from '../../tailwind.config'

const { colors, screens, fontFamily, margin, spacing, fontWeight } = twConfig.theme.extend
const breakPoints = {
  sm: `@media (min-width: ${screens.sm})`,
  md: `@media (min-width: ${screens.md})`,
  lg: `@media (min-width: ${screens.lg})`,
  xl: `@media (min-width: ${screens.xl})`,
}
const zIndex = {
  nav: 2000,
  topNav: 2002,
  navOverlay: 2000,
  mobileNav: 2001,
  scrollToTop: 1999,
}
export const styleConfig = {
  breakPoints,
  zIndex,
  screens,
  colors,
  fontFamily,
  margin,
  spacing,
  fontWeight,
}

export default styleConfig

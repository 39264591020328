import 'styles/tailwind.css'

import anim from './anim'
import { css } from '@emotion/core'
import fonts from './fonts'
import img from './img'
import styleConfig from 'config/styleConfig'

// import styleConfig from 'config/styleConfig'
// console.log(styleConfig.breakPoints)
const vars = css`
  :root {
    --yellow: #ffc600;
  }
`
const printStyles = css`
  .print-show {
    display: none;
  }
  @media print {
    .print-hide {
      display: none !important;
    }
    .print-show {
      display: block !important;
    }
  }

  @media print {
    .pagebreak {
      page-break-before: always;
    } /* page-break-after works, as well */
  }
`
const gatsbyStyle = css`
  #___gatsby {
    background: white;
  }
  #___gatsby,
  #gatsby-focus-wrapper {
    min-height: 100vh;
  }
`
const globalStyles = css`
  /* ${gatsbyStyle} */
  ${vars}
  html {
  scroll-behavior: smooth;
}

  html,
  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  /* font-display text-xl font-light tracking-wide */
    font-family: ${styleConfig.fontFamily.body};
    background: white !important;
    font-weight: ${styleConfig.fontWeight.bodyWeight};
    font-size: 1rem;
    letter-spacing:normal;
    /*line-height:20px;*/
    color: ${styleConfig.colors.bodyFontColor};
  }

  .hotPink{
    background:hotpink
  }
  .ReactModal__Overlay{
  z-index:5000 !important;
}
.aboutIcon{
  color:red;
}
.ico-moon {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}
.img-fluid {
    max-width: 100%;
    height: auto;
}
[data-aos="new-animation"] {
  opacity: 0;
  transition-property: transform, opacity;

  &.aos-animate {
    opacity: 1;
  }

  @media screen and (min-width: 768px) {
    transform: translateX(100px);

    &.aos-animate {
      transform: translateX(0);
    }
  }
}


.screenshot {
    border-radius: .625rem;
    box-shadow: 25px 60px 125px -25px rgba(80,102,144,.1), 16px 40px 75px -40px rgba(0,0,0,.2);
}

  ${printStyles}
  ${fonts}
  ${img}
  ${anim}
`
export default globalStyles

/*
const getBreakPoints = (): { [name: string]: string } => {
    const obj: { [name: string]: string } = {}
    Object.keys(styleConfig.screens).forEach((bp) => {
      const key = bp as keyof typeof styleConfig.screens
      const val = styleConfig.screens[key]
      obj[bp] = `@media (min-width: ${val}px)`
    })
    return obj
  }
  */

import { Link } from 'gatsby'
import { NavMenuContainer } from './styles'
import React from 'react'
import { sitePaths } from 'config/paths'

interface IProps {
  onNavSubMenuClick: (e: React.MouseEvent | React.KeyboardEvent) => void
  subVisible: string | undefined | null
  onSubOverlayClick: (e: React.MouseEvent | React.KeyboardEvent) => void
  className?: string
  // onNavClick: (e: React.MouseEvent | React.KeyboardEvent) => void
}
const NavMenu = ({ onSubOverlayClick, subVisible, className }: IProps): React.ReactElement => {
  return (
    <>
      <div role="button" tabIndex={0} onKeyDown={onSubOverlayClick} onClick={onSubOverlayClick} className={`navSubMenuOverlay ${subVisible ? 'active ' : ''}`}>
        &nbsp;
      </div>
      <NavMenuContainer className={className}>
        {/* <li>
          <Link to={sitePaths.appLinks.home}>HOME</Link>
  </li>*/}
        <li>
          <Link activeClassName={'activeLink'} to={sitePaths.appLinks.about}>
            ABOUT
          </Link>
        </li>
        <li>
          <Link activeClassName={'activeLink'} data-submenu-id="portfolio" to={sitePaths.appLinks.portfolio}>
            PORTFOLIO
          </Link>
        </li>
        <li>
          <Link activeClassName={'activeLink'} data-submenu-id="products" to={sitePaths.appLinks.products}>
            PRODUCTS
          </Link>
          {/* 
          <div className={`navItemDropdown ${subVisible === 'files' ? 'active ' : ''}`}>
            <Link className="text-primary" to={sitePaths.appLinks.home}>
              200 Series
            </Link>
            <hr />
            <Link className="text-primary" to={sitePaths.appLinks.home}>
              300 Series
            </Link>
            <hr />
            <Link className="text-primary" to={sitePaths.appLinks.home}>
              400 Series
            </Link>
          </div>
          */}
        </li>
        <li>
          <Link activeClassName={'activeLink'} data-submenu-id="contact" to={sitePaths.appLinks.contact}>
            CONTACT
          </Link>
        </li>
      </NavMenuContainer>
    </>
  )
}

export default NavMenu

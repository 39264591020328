import React, { ReactElement } from 'react'

import classNames from 'classnames'
import style from './style'

interface IProps {
  isActive: boolean
  handleAction: (e: React.MouseEvent | React.KeyboardEvent) => void
}
const BurgerBtn = ({ handleAction, isActive = false }: IProps): ReactElement => (
  <div css={style} role="button" tabIndex={0} className={classNames({ active: isActive })} onClick={handleAction} onKeyDown={handleAction}></div>
)

export default BurgerBtn

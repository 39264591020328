import { css } from '@emotion/core'

const anim = css`
  .lift {
    transition: box-shadow 0.25s ease, transform 0.25s ease;
    &:focus,
    &:hover {
      box-shadow: 0 1rem 2.5rem rgba(22, 28, 45, 0.1), 0 0.5rem 1rem -0.75rem rgba(22, 28, 45, 0.1) !important;
      transform: translate3d(0, -3px, 0);
    }
  }
  .lift-lg:focus,
  .lift-lg:hover {
    box-shadow: 0 2rem 5rem rgba(22, 28, 45, 0.1), 0 0.5rem 1rem -0.75rem rgba(22, 28, 45, 0.05) !important;
    transform: translate3d(0, -5px, 0);
  }
`

export default anim

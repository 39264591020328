import { css } from '@emotion/core'

const imageStyles = css`
  .img-dark-overlay {
    z-index: 1;
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
  }
  .img-responsive {
    max-width: 100%;
    height: auto;
  }
`

export default imageStyles

import { AppContext, mobileMenuVisibleAction, navSubMenuVisibleAction } from 'state/context/appContext'
import { MainNavContainer, ScrollToTopContainer } from './style'

import BurgerBtn from 'components/buttons/burgerBtn'
import { Link } from 'gatsby'
import MatrixLogo from 'media/svg/logo.svg'
import MatrixLogoText from 'media/svg/logoText.svg'
import NavMenu from './navMenu'
import NavMobile from './navMobile'
import React from 'react'
import ScrollToTopBtn from 'components/buttons/scrollToTopBtn'
import cn from 'classnames'
import debounce from 'lodash.debounce'
import { sitePaths } from 'config/paths'

const Navigation = (): React.ReactElement => {
  const { state, dispatch } = React.useContext(AppContext)
  const [showDarkMenu, setShowDarkMenu] = React.useState(false)
  React.useEffect(() => {
    const pageYOffset = 30
    const eventName = 'scroll'
    // Create event listener that calls handler function stored in ref
    const eventListener = debounce((): void => {
      setShowDarkMenu(window.pageYOffset > pageYOffset)
    })
    document.addEventListener(eventName, eventListener, { passive: true })
    // on cleanup
    return (): void => {
      document.removeEventListener(eventName, eventListener)
    }
  }, [])
  const handleOverLayClick = (): void => {
    dispatch(mobileMenuVisibleAction(false))
  }
  const handleNavSubClick = (e: React.MouseEvent | React.KeyboardEvent): void => {
    dispatch(navSubMenuVisibleAction(e.currentTarget.getAttribute('data-submenu-id')))
  }

  const handleNavSubOverLayClick = (): void => {
    dispatch(navSubMenuVisibleAction(null))
  }
  const handleBurgerBtn = (): void => {
    dispatch(mobileMenuVisibleAction(!state.mobileMenuVisible))
  }

  return (
    <>
      <MainNavContainer className={cn({ darkMode: showDarkMenu })}>
        <nav className="container relative">
          <Link to={sitePaths.appLinks.home} className="mainNav-logo">
            <div className="logoWrap">
              <MatrixLogo className="mainNav-logo-1" />
              <MatrixLogoText className="mainNav-logo-2" />
            </div>
          </Link>
          <div className="mainNav-menu">
            <NavMenu className={cn({ darkMode: showDarkMenu })} onNavSubMenuClick={handleNavSubClick} subVisible={state.navSubMenuVisible} onSubOverlayClick={handleNavSubOverLayClick} />
          </div>
          <div className="mainNav-hamburgerBtn">
            <BurgerBtn isActive={state.mobileMenuVisible} handleAction={handleBurgerBtn} />
          </div>
        </nav>
      </MainNavContainer>
      <NavMobile active={state.mobileMenuVisible} onClickOverLay={handleOverLayClick} />
      <ScrollToTopContainer className="print-hide text-blue-700 ">
        <ScrollToTopBtn />
      </ScrollToTopContainer>
    </>
  )
}

export default Navigation

/*
const Navigation0 = (): React.ReactElement => {
  return (
    <>
      <nav className="flex items-center text-gray-900 fixed w-full z-30 top-0 text-white bg-white border-b border-gray-200 top-0 inset-x-0 z-100 h-16">
        <div className="w-full max-w-screen-xl relative mx-auto px-6">
          <div className="flex items-center -mx-6">
            <div className="lg:w-1/4 xl:w-1/5 pl-6 pr-6 lg:pr-8">
              <div className="flex items-center">
                <a href="/" className="block lg:mr-4 font-semibold text-red-700">
                  Title
                </a>
              </div>
            </div>

            <div className="flex flex-grow justify-end bg-gray-600">
              <div className="hidden lg:flex lg:justify-between ">
                <div className="flex">Links</div>
                <div className="flex">Links</div>
                <div className="flex">Links</div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  )
}
*/

import React, { ReactElement, useEffect } from 'react'

import { ArrowUpCircle } from '@styled-icons/feather'
import SiteIcon from 'components/icons/SiteIcon'
import debounce from 'lodash.debounce'

const pageYOffset = 300

const ScrollToTopBtn = (): ReactElement | null => {
  const [isVisible, setIsVisible] = React.useState(false)

  useEffect(() => {
    const eventName = 'scroll'
    // Create event listener that calls handler function stored in ref
    const eventListener = debounce((): void => {
      setIsVisible(window.pageYOffset > pageYOffset)
    })
    document.addEventListener(eventName, eventListener, { passive: true })
    // on cleanup
    return (): void => {
      document.removeEventListener(eventName, eventListener)
    }
  }, [])
  const scrollToTop = (): void => {
    // scroll.scrollToTop();
    window.scroll(0, 0)
  }
  if (!isVisible) {
    return null
  }

  return (
    <div className="btn-scroll-to-top">
      <button className="btn lift" onClick={scrollToTop} style={{ backgroundColor: 'white', padding: 5 }}>
        <SiteIcon Icon={ArrowUpCircle} title="Scroll To Top" />
      </button>
    </div>
  )
}

export default ScrollToTopBtn

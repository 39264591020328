import styleConfig from 'config/styleConfig'
import styled from '@emotion/styled'

const { colors, breakPoints, zIndex } = styleConfig

export const ScrollToTopContainer = styled.div`
  position: fixed;
  bottom: 1.5rem;
  right: 1.5rem;
  z-index: ${styleConfig.zIndex.scrollToTop};
`

export const MainNavContainer = styled.div`
  display: flex;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${zIndex.topNav};
  height: 80px;
  transition: all 0.5s ease;
  color: ${colors.gray[900]};
  justify-content: center;
  align-content: flex-start;
  background: white;
  border-bottom: 1px solid #cccccc;

  ${breakPoints.lg} {
    &.darkMode {
      color: ${colors.gray[900]};
      border-bottom: 1px solid #cccccc;
      background-color: rgba(255, 255, 255, 0.85);
      backdrop-filter: blur(10px);
      box-shadow: 0 0.125em 0.125em 0 rgba(0, 0, 0, 0.125);
    }
    border: none;
    background: transparent;
    color: white;
    padding: 1rem;
    height: 100px;
  }
  .logoWrap {
    position: relative;
    display: flex;
    margin: 0;
    padding: 0;
    height: 50px;
    margin: 1rem 0 0 1rem;
    padding: 0px;
    ${breakPoints.lg} {
      margin: 0.9rem 0 0 0;
    }
  }
  .mainNav-logo {
    display: flex;
    flex-direction: row;
    position: relative;
    .mainNav-logo-1 {
      width: 50px;
      display: flex;
      ${breakPoints.lg} {
        width: 70px;
      }
    }
    .mainNav-logo-2 {
      margin-left: 1rem;
      transition: all 0.5s ease-in-out;
      clip-path: circle(40% at 0% 20%);
      ${breakPoints.md} {
        clip-path: circle(90%);
      }
      overflow: hidden;
    }

    svg {
      fill: currentColor;
    }
  }
  nav {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    ${breakPoints.lg} {
      justify-content: flex-start;
    }
  }
  .mainNav-menu {
    display: none;
    margin: 2.2rem 0 0;
    ${breakPoints.lg} {
      margin: 1.4rem 0 0;
      flex: 1;
      align-content: flex-end;
      display: flex;
      justify-content: flex-end;
    }
  }
  .mainNav-hamburgerBtn {
    display: flex;
    flex: 0 0 50px;
    padding: 0 1rem;
    position: absolute;
    top: 0px;
    right: 0px;
    height: 100%;
    align-items: center;
    ${breakPoints.lg} {
      display: none;
    }
  }
`

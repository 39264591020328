import { css } from '@emotion/core'

const fonts = css`
  /*@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,400italic');*/
  @import url('https://fonts.googleapis.com/css?family=Roboto:400,100,300,600,400italic,700');
  @import url('https://fonts.googleapis.com/css?family=Lato:400,300');
  @import url('https://fonts.googleapis.com/css?family=Nunito+Sans:400,400i,600,700,800&display=swap');
  /*
  @import url('https://fonts.googleapis.com/css?family=HKGroteskPro:400,300');
  font-family: HKGroteskPro, serif;*/

  @font-face {
    font-family: 'icomoon';
    font-weight: normal;
    font-style: normal;
    src: url('./fonts/icomoon.eot');
    src: url('./fonts/icomoon.eot') format('embedded-opentype'), url('./fonts/icomoon.ttf') format('truetype'), url('./fonts/icomoon.woff') format('woff'), url('./fonts/icomoon.svg') format('svg');
  }

  [class^='icon-'],
  [class*=' icon-'] {
    font-family: 'icomoon' !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .icon-eye:before {
    content: '\e044';
  }
  .icon-paper-clip:before {
    content: '\e064';
  }
  .icon-mail:before {
    content: '\e065';
  }
  .icon-toggle:before {
    content: '\e066';
  }
  .icon-layout:before {
    content: '\e067';
  }
  .icon-link:before {
    content: '\e068';
  }
  .icon-bell:before {
    content: '\e069';
  }
  .icon-lock2:before {
    content: '\e06a';
  }
  .icon-unlock:before {
    content: '\e06b';
  }
  .icon-ribbon2:before {
    content: '\e06c';
  }
  .icon-image:before {
    content: '\e06d';
  }
  .icon-signal:before {
    content: '\e06e';
  }
  .icon-target2:before {
    content: '\e06f';
  }
  .icon-clipboard2:before {
    content: '\e070';
  }
  .icon-clock2:before {
    content: '\e071';
  }
  .icon-watch:before {
    content: '\e072';
  }
  .icon-air-play:before {
    content: '\e073';
  }
  .icon-camera2:before {
    content: '\e074';
  }
  .icon-video2:before {
    content: '\e075';
  }
  .icon-disc:before {
    content: '\e076';
  }
  .icon-printer2:before {
    content: '\e077';
  }
  .icon-monitor:before {
    content: '\e078';
  }
  .icon-server:before {
    content: '\e079';
  }
  .icon-cog:before {
    content: '\e07a';
  }
  .icon-heart2:before {
    content: '\e07b';
  }
  .icon-paragraph:before {
    content: '\e07c';
  }
  .icon-align-justify:before {
    content: '\e07d';
  }
  .icon-align-left:before {
    content: '\e07e';
  }
  .icon-align-center:before {
    content: '\e07f';
  }
  .icon-align-right:before {
    content: '\e080';
  }
  .icon-book:before {
    content: '\e081';
  }
  .icon-layers2:before {
    content: '\e082';
  }
  .icon-stack:before {
    content: '\e083';
  }
  .icon-stack-2:before {
    content: '\e084';
  }
  .icon-paper:before {
    content: '\e085';
  }
  .icon-paper-stack:before {
    content: '\e086';
  }
  .icon-search2:before {
    content: '\e087';
  }
  .icon-zoom-in:before {
    content: '\e088';
  }
  .icon-zoom-out:before {
    content: '\e089';
  }
  .icon-reply:before {
    content: '\e08a';
  }
  .icon-circle-plus:before {
    content: '\e08b';
  }
  .icon-circle-minus:before {
    content: '\e08c';
  }
  .icon-circle-check:before {
    content: '\e08d';
  }
  .icon-circle-cross:before {
    content: '\e08e';
  }
  .icon-square-plus:before {
    content: '\e08f';
  }
  .icon-square-minus:before {
    content: '\e090';
  }
  .icon-square-check:before {
    content: '\e091';
  }
  .icon-square-cross:before {
    content: '\e092';
  }
  .icon-microphone:before {
    content: '\e093';
  }
  .icon-record:before {
    content: '\e094';
  }
  .icon-skip-back:before {
    content: '\e095';
  }
  .icon-rewind:before {
    content: '\e096';
  }
  .icon-play:before {
    content: '\e097';
  }
  .icon-pause:before {
    content: '\e098';
  }
  .icon-stop:before {
    content: '\e099';
  }
  .icon-fast-forward:before {
    content: '\e09a';
  }
  .icon-skip-forward:before {
    content: '\e09b';
  }
  .icon-shuffle:before {
    content: '\e09c';
  }
  .icon-repeat:before {
    content: '\e09d';
  }
  .icon-folder:before {
    content: '\e09e';
  }
  .icon-umbrella:before {
    content: '\e09f';
  }
  .icon-moon:before {
    content: '\e0a0';
  }
  .icon-thermometer:before {
    content: '\e0a1';
  }
  .icon-drop:before {
    content: '\e0a2';
  }
  .icon-sun:before {
    content: '\e0a3';
  }
  .icon-cloud2:before {
    content: '\e0a4';
  }
  .icon-cloud-upload:before {
    content: '\e0a5';
  }
  .icon-cloud-download:before {
    content: '\e0a6';
  }
  .icon-upload2:before {
    content: '\e0a7';
  }
  .icon-download2:before {
    content: '\e0a8';
  }
  .icon-location:before {
    content: '\e0a9';
  }
  .icon-location-2:before {
    content: '\e0aa';
  }
  .icon-map2:before {
    content: '\e0ab';
  }
  .icon-battery:before {
    content: '\e0ac';
  }
  .icon-head:before {
    content: '\e0ad';
  }
  .icon-briefcase2:before {
    content: '\e0ae';
  }
  .icon-speech-bubble:before {
    content: '\e0af';
  }
  .icon-anchor2:before {
    content: '\e0b0';
  }
  .icon-globe2:before {
    content: '\e0b1';
  }
  .icon-box:before {
    content: '\e0b2';
  }
  .icon-reload:before {
    content: '\e0b3';
  }
  .icon-share:before {
    content: '\e0b4';
  }
  .icon-marquee:before {
    content: '\e0b5';
  }
  .icon-marquee-plus:before {
    content: '\e0b6';
  }
  .icon-marquee-minus:before {
    content: '\e0b7';
  }
  .icon-tag:before {
    content: '\e0b8';
  }
  .icon-power:before {
    content: '\e0b9';
  }
  .icon-command:before {
    content: '\e0ba';
  }
  .icon-alt:before {
    content: '\e0bb';
  }
  .icon-esc:before {
    content: '\e0bc';
  }
  .icon-bar-graph:before {
    content: '\e0bd';
  }
  .icon-bar-graph-2:before {
    content: '\e0be';
  }
  .icon-pie-graph:before {
    content: '\e0bf';
  }
  .icon-star:before {
    content: '\e0c0';
  }
  .icon-arrow-left:before {
    content: '\e0c1';
  }
  .icon-arrow-right:before {
    content: '\e0c2';
  }
  .icon-arrow-up:before {
    content: '\e0c3';
  }
  .icon-arrow-down:before {
    content: '\e0c4';
  }
  .icon-volume:before {
    content: '\e0c5';
  }
  .icon-mute:before {
    content: '\e0c6';
  }
  .icon-content-right:before {
    content: '\e100';
  }
  .icon-content-left:before {
    content: '\e101';
  }
  .icon-grid2:before {
    content: '\e102';
  }
  .icon-grid-2:before {
    content: '\e103';
  }
  .icon-columns:before {
    content: '\e104';
  }
  .icon-loader:before {
    content: '\e105';
  }
  .icon-bag:before {
    content: '\e106';
  }
  .icon-ban:before {
    content: '\e107';
  }
  .icon-flag2:before {
    content: '\e108';
  }
  .icon-trash:before {
    content: '\e109';
  }
  .icon-expand2:before {
    content: '\e110';
  }
  .icon-contract:before {
    content: '\e111';
  }
  .icon-maximize:before {
    content: '\e112';
  }
  .icon-minimize:before {
    content: '\e113';
  }
  .icon-plus:before {
    content: '\e114';
  }
  .icon-minus:before {
    content: '\e115';
  }
  .icon-check:before {
    content: '\e116';
  }
  .icon-cross:before {
    content: '\e117';
  }
  .icon-move:before {
    content: '\e118';
  }
  .icon-delete:before {
    content: '\e119';
  }
  .icon-menu:before {
    content: '\e120';
  }
  .icon-archive:before {
    content: '\e121';
  }
  .icon-inbox:before {
    content: '\e122';
  }
  .icon-outbox:before {
    content: '\e123';
  }
  .icon-file:before {
    content: '\e124';
  }
  .icon-file-add:before {
    content: '\e125';
  }
  .icon-file-subtract:before {
    content: '\e126';
  }
  .icon-help:before {
    content: '\e127';
  }
  .icon-open:before {
    content: '\e128';
  }
  .icon-ellipsis:before {
    content: '\e129';
  }
  .icon-mobile:before {
    content: '\e000';
  }
  .icon-laptop:before {
    content: '\e001';
  }
  .icon-desktop:before {
    content: '\e002';
  }
  .icon-tablet:before {
    content: '\e003';
  }
  .icon-phone:before {
    content: '\e004';
  }
  .icon-document:before {
    content: '\e005';
  }
  .icon-documents:before {
    content: '\e006';
  }
  .icon-search:before {
    content: '\e007';
  }
  .icon-clipboard:before {
    content: '\e008';
  }
  .icon-newspaper:before {
    content: '\e009';
  }
  .icon-notebook:before {
    content: '\e00a';
  }
  .icon-book-open:before {
    content: '\e00b';
  }
  .icon-browser:before {
    content: '\e00c';
  }
  .icon-calendar:before {
    content: '\e00d';
  }
  .icon-presentation:before {
    content: '\e00e';
  }
  .icon-picture:before {
    content: '\e00f';
  }
  .icon-pictures:before {
    content: '\e010';
  }
  .icon-video:before {
    content: '\e011';
  }
  .icon-camera:before {
    content: '\e012';
  }
  .icon-printer:before {
    content: '\e013';
  }
  .icon-toolbox:before {
    content: '\e014';
  }
  .icon-briefcase:before {
    content: '\e015';
  }
  .icon-wallet:before {
    content: '\e016';
  }
  .icon-gift:before {
    content: '\e017';
  }
  .icon-bargraph:before {
    content: '\e018';
  }
  .icon-grid:before {
    content: '\e019';
  }
  .icon-expand:before {
    content: '\e01a';
  }
  .icon-focus:before {
    content: '\e01b';
  }
  .icon-edit:before {
    content: '\e01c';
  }
  .icon-adjustments:before {
    content: '\e01d';
  }
  .icon-ribbon:before {
    content: '\e01e';
  }
  .icon-hourglass:before {
    content: '\e01f';
  }
  .icon-lock:before {
    content: '\e020';
  }
  .icon-megaphone:before {
    content: '\e021';
  }
  .icon-shield:before {
    content: '\e022';
  }
  .icon-trophy:before {
    content: '\e023';
  }
  .icon-flag:before {
    content: '\e024';
  }
  .icon-map:before {
    content: '\e025';
  }
  .icon-puzzle:before {
    content: '\e026';
  }
  .icon-basket:before {
    content: '\e027';
  }
  .icon-envelope:before {
    content: '\e028';
  }
  .icon-streetsign:before {
    content: '\e029';
  }
  .icon-telescope:before {
    content: '\e02a';
  }
  .icon-gears:before {
    content: '\e02b';
  }
  .icon-key:before {
    content: '\e02c';
  }
  .icon-paperclip:before {
    content: '\e02d';
  }
  .icon-attachment:before {
    content: '\e02e';
  }
  .icon-pricetags:before {
    content: '\e02f';
  }
  .icon-lightbulb:before {
    content: '\e030';
  }
  .icon-layers:before {
    content: '\e031';
  }
  .icon-pencil:before {
    content: '\e032';
  }
  .icon-tools:before {
    content: '\e033';
  }
  .icon-tools-2:before {
    content: '\e034';
  }
  .icon-scissors:before {
    content: '\e035';
  }
  .icon-paintbrush:before {
    content: '\e036';
  }
  .icon-magnifying-glass:before {
    content: '\e037';
  }
  .icon-circle-compass:before {
    content: '\e038';
  }
  .icon-linegraph:before {
    content: '\e039';
  }
  .icon-mic:before {
    content: '\e03a';
  }
  .icon-strategy:before {
    content: '\e03b';
  }
  .icon-beaker:before {
    content: '\e03c';
  }
  .icon-caution:before {
    content: '\e03d';
  }
  .icon-recycle:before {
    content: '\e03e';
  }
  .icon-anchor:before {
    content: '\e03f';
  }
  .icon-profile-male:before {
    content: '\e040';
  }
  .icon-profile-female:before {
    content: '\e041';
  }
  .icon-bike:before {
    content: '\e042';
  }
  .icon-wine:before {
    content: '\e043';
  }
  .icon-globe:before {
    content: '\e045';
  }
  .icon-genius:before {
    content: '\e046';
  }
  .icon-map-pin:before {
    content: '\e047';
  }
  .icon-dial:before {
    content: '\e048';
  }
  .icon-chat:before {
    content: '\e049';
  }
  .icon-heart:before {
    content: '\e04a';
  }
  .icon-cloud:before {
    content: '\e04b';
  }
  .icon-upload:before {
    content: '\e04c';
  }
  .icon-download:before {
    content: '\e04d';
  }
  .icon-target:before {
    content: '\e04e';
  }
  .icon-hazardous:before {
    content: '\e04f';
  }
  .icon-piechart:before {
    content: '\e050';
  }
  .icon-speedometer:before {
    content: '\e051';
  }
  .icon-global:before {
    content: '\e052';
  }
  .icon-compass:before {
    content: '\e053';
  }
  .icon-lifesaver:before {
    content: '\e054';
  }
  .icon-clock:before {
    content: '\e055';
  }
  .icon-aperture:before {
    content: '\e056';
  }
  .icon-quote:before {
    content: '\e057';
  }
  .icon-scope:before {
    content: '\e058';
  }
  .icon-alarmclock:before {
    content: '\e059';
  }
  .icon-refresh:before {
    content: '\e05a';
  }
  .icon-happy:before {
    content: '\e05b';
  }
  .icon-sad:before {
    content: '\e05c';
  }
  .icon-facebook:before {
    content: '\e05d';
  }
  .icon-twitter:before {
    content: '\e05e';
  }
  .icon-googleplus:before {
    content: '\e05f';
  }
  .icon-rss:before {
    content: '\e060';
  }
  .icon-tumblr:before {
    content: '\e061';
  }
  .icon-linkedin:before {
    content: '\e062';
  }
  .icon-dribbble:before {
    content: '\e063';
  }
`

export default fonts

import { css } from '@emotion/core'
import styleConfig from 'config/styleConfig'
import styled from '@emotion/styled'

const { breakPoints } = styleConfig

export const NavMenuContainer = styled.ul`
  display: flex;
  list-style: none;
  position: relative;
  margin: 0;
  padding: 0 1.5rem 0 0;
  height: fit-content;
  li {
    padding: 0 1rem 0 0;
    position: relative;
  }
  &.darkMode {
    li a {
      &:after {
        background: black !important;
      }
    }
  }
  li a.activeLink {
    opacity: 0.4;
    cursor: none;
    pointer-events: none;
  }
  li a {
    font-weight: 500;
    position: relative;
    &:after {
      content: ' ';
      height: 2px;
      width: 0;
      position: absolute;
      bottom: -10px;
      left: 0;
      opacity: 0;
      transition: all 0.3s;
      background: #fff;
    }
    :hover {
      &:after {
        opacity: 1;
        width: 80%;
      }
    }
  }
`

const style = css`
  .navSubMenuOverlay {
    display: none;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute;
    height: 100vh;
    width: 100%;
    &.active {
      ${breakPoints.lg} {
        display: block;
        background: rgba(5, 10, 20, 0.2) !important;
        animation: fade 500ms;
      }
    }
    z-index: 1000;
  }
  .navMenu {
    li {
      flex: 0 0;
      position: relative;
      display: list-item;
      padding: 0 0 1em 2.5em;
      height: fit-content;
      a {
        color: white;
      }
      &:hover .navItemDropdown,
      .navItemDropdown.active {
        opacity: 1;
        transform: translate3d(-50%, 0, 0);
        visibility: visible;
      }
      &:first-child .navItemDropdown {
        padding: 0px;
      }
      &:hover .nav-border:before {
        transform: scaleX(1);
      }

      .navItemDropdown {
        display: block;
        padding: 2rem !important;
        flex-direction: row;
        position: absolute;
        z-index: 100;
        left: 50%;
        box-shadow: 0 1.5rem 4rem rgba(22, 28, 45, 0.15);
        visibility: hidden;
        opacity: 0;
        top: 40px;
        transform: translate3d(-50%, 10px, 0);
        transition: all 0.2s ease-in-out;
        transition-property: opacity, visibility, transform;
        transform-origin: top center;
        background-color: #fff;
        background-clip: padding-box;
        border: 0 solid rgba(22, 28, 45, 0.15);
        border-radius: 0.375rem;
        color: $gray-500 !important;
        .btn-text {
          font-weight: normal;
        }
        a,
        a:visited,
        .btn-text {
          color: $dark;
          transition: color 0.3s ease-in;
        }
        a:hover,
        .btn-text:hover {
          color: $dark !important;
        }
        button {
          text-align: left;
          padding: 0;
        }
      }

      .nav-border {
        display: block;
        height: 3px;
        margin-top: 10px;
        transform: translateZ(0);
        box-shadow: 0 0 1px transparent;
        backface-visibility: hidden;
        position: relative;
        transition-property: color;
        transition-duration: 0.3s;
      }

      .nav-border:before {
        content: '';
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #fff;
        transform: scaleX(0);
        transform-origin: 0 50%;
        transition-property: transform;
        transition-duration: 0.3s;
        transition-timing-function: ease-out;
      }
    }
  }
`
export default style

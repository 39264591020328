import styleConfig from 'config/styleConfig'
import styled from '@emotion/styled'

const { breakPoints, zIndex } = styleConfig

export const NavMobileOverlay = styled.div`
  display: none;
  top: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: fixed;
  z-index: ${styleConfig.zIndex.navOverlay};
  background: rgba(5, 10, 20, 0.7) !important;
  &.darkMode {
    background: rgba(5, 10, 20, 0.7) !important;
  }
  &.active {
    display: block;
    animation: fade 500ms;
  }
  ${breakPoints.lg} {
    display: none;
    opacity: 0;
  }
`

export const NavMobileMenuContainer = styled.div`
  background: ${styleConfig.colors.gray[600]};
  border-radius: 0 0 0.375rem 0;
  box-shadow: 0 1.5rem 4rem rgba(22, 28, 45, 0.15);
  overflow-y: auto;
  touch-action: pan-y;
  z-index: ${zIndex.mobileNav};
  transition: all 0.5s ease-in-out;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  opacity: 0;
  height: 100vh;
  width: 100%;
  padding: 100px 2em 0;
  font-size: 1.1em;
  transform: translateX(-100%);
  ${breakPoints.md} {
    max-width: 400px;
    padding: 150px 2em 0;
  }
  ${breakPoints.lg} {
    display: none;
    opacity: 0;
  }
  &.active {
    opacity: 1;
    transform: translateX(0);
  }
  ul {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  li {
    list-style: none;
    color: #ffffff;
    margin: 1rem 0;
  }
  a.activeLink {
    opacity: 0.4;
    cursor: none;
  }
  a {
    color: #ffffff;
    display: block;
    text-align: left;
    position: relative;
    &:after {
      content: ' ';
      height: 1px;
      width: 0;
      position: absolute;
      bottom: -10px;
      left: 0;
      opacity: 0;
      transition: all 0.3s;
      background: #fff;
    }
    :hover {
      &:after {
        opacity: 1;
        width: 100%;
      }
    }
  }
  .linkWithSubMenu {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    transition: padding, opacity, max-height 1s;
    &:focus {
      outline: none;
    }
    /*svg {
        color: white !important;
        float: right;
      }*/
    div {
      display: block;
      &:focus {
        outline: none;
      }
      svg {
        transition: all 1s;
        width: 1.5em;
        height: 1.5em;
        float: right;
        margin: 0;
        transform: rotate(-90deg);
      }
    }

    ul {
      max-height: 0px;
      padding: 0 0 0 2em;
      transition: all 0.5s;
      opacity: 0;
      width: 100%;
      display: flex;
    }
    &.isExpanded {
      ul {
        padding: 1em 0 1em 2em;
        opacity: 1;
        max-height: 500px;
      }
      div svg {
        transform: rotate(-180deg);
      }
    }
  }
`

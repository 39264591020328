import { NavMobileMenuContainer, NavMobileOverlay } from './style'

import { Link } from 'gatsby'
import React from 'react'
// import { SiteIcons } from 'components/icons/SiteIcon'
import cn from 'classnames'
import { sitePaths } from 'config/paths'

interface IProps {
  active: boolean
  onClickOverLay: (e: React.MouseEvent) => void
}
const NavMobile = ({ active, onClickOverLay }: IProps): React.ReactElement => {
  /*const [productsSubExpanded, setProductsSubExpanded] = React.useState(false)
  const handleSubNav = (): void => {
    setProductsSubExpanded(!productsSubExpanded)
  }*/
  return (
    <>
      <NavMobileOverlay onClick={onClickOverLay} className={cn('print-hide', { active })} />
      <NavMobileMenuContainer className={cn({ active })}>
        <ul>
          <li>
            <Link activeClassName={'activeLink'} to={sitePaths.appLinks.home}>
              <span>Home</span>
            </Link>
          </li>
          <li>
            <Link activeClassName={'activeLink'} to={sitePaths.appLinks.about}>
              <span>About</span>
            </Link>
          </li>
          <li>
            <Link activeClassName={'activeLink'} to={sitePaths.appLinks.portfolio}>
              <span>Portfolio</span>
            </Link>
          </li>
          {/*
        <li className={cn('linkWithSubMenu', { isExpanded: productsSubExpanded })}>
          <div role="button" tabIndex={0} onClick={handleSubNav} onKeyDown={handleSubNav}>
            Products
            <SiteIcons.ExpandLess />
          </div>
          <ul>
            <li>
              <a href="#">200 Series</a>
            </li>
            <li>
              <a href="#">300 Series</a>
            </li>
            <li>
              <a href="#">400 Series</a>
            </li>
            <li>
              <a href="#">450 Series</a>
            </li>
            <li>
              <a href="#">600 Series</a>
            </li>
            <li>
              <a href="#">Curtainwall</a>
            </li>
            <li>
              <a href="#">Entrances</a>
            </li>
            <li>
              <a href="#">Fenestration</a>
            </li>
          </ul>
        </li>
        */}

          <li>
            <Link activeClassName={'activeLink'} to={sitePaths.appLinks.products}>
              <span>Products</span>
            </Link>
          </li>
          <li>
            <Link activeClassName={'activeLink'} to={sitePaths.appLinks.contact}>
              <span>Contact</span>
            </Link>
          </li>
        </ul>
      </NavMobileMenuContainer>
    </>
  )
}

export default NavMobile
